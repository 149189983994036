<template>
  <section>
    <TitleBar tabsParent="backoffice">
      Backoffice
      <template #toolbar-content>
        <TableButtonGroup
          :tableKey="tableKey"
          @rechercher="rechercher"
        ></TableButtonGroup>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <PaginatedTable
        :fields="fields"
        :busy="busy"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
      >
        <template #cell(email)="{ item }">
          <EmailComponent :value="item.email"></EmailComponent>
        </template>
        <template #cell(prenom)="{ item }">
          <span
            class="d-block"
            v-for="contact in item.contacts"
            :key="contact.codeContact"
          >
            {{ contact.prenom || "" }}
          </span>
          <em v-if="item.contacts.length === 0" class="text-danger">
            Aucun contact
          </em>
        </template>
        <template #cell(nom)="{ item }">
          <span
            class="d-block"
            v-for="contact in item.contacts"
            :key="contact.codeContact"
          >
            {{ contact.nom || "" }}
          </span>
        </template>
        <template #cell(codeTiers)="{ item }">
          <span
            class="d-block"
            v-for="contact in item.contacts"
            :key="contact.codeContact"
          >
            {{ contact.codeTiers || "" }}
          </span>
          <em v-if="item.contacts.length === 0" class="text-danger">
            Aucun tiers
          </em>
        </template>
        <template #cell(raisonSociale)="{ item }">
          <span
            class="d-block"
            v-for="contact in item.contacts"
            :key="contact.codeContact"
          >
            {{ contact.raisonSociale || "" }}
          </span>
        </template>
        <template #cell(actions)="{ item }">
          <b-button-toolbar>
            <b-button-group size="sm">
              <b-button
                class="rounded-left"
                variant="primary"
                @click="openStatutDialog(item.email)"
              >
                Statut
              </b-button>
              <b-button variant="primary" @click="openGroupsEditor(item.email)">
                Groupes
              </b-button>
              <b-button
                class="rounded-right"
                variant="danger"
                @click="resetPassword(item.email)"
              >
                R&eacute;initialiser
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </template>
      </PaginatedTable>
      <ConfirmationDialog ref="confirmationDialog"></ConfirmationDialog>
      <AccountStatusDialog ref="accountStatusDialog"></AccountStatusDialog>
      <GroupsEditionDialog ref="groupsEditionDialog"></GroupsEditionDialog>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import TableButtonGroup from "../../components/controls/TableButtonGroup.vue";
import EmailComponent from "../../components/EmailComponent.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog.vue";
import AccountStatusDialog from "../../components/dialogs/AccountStatusDialog.vue";
import GroupsEditionDialog from "../../components/dialogs/GroupsEditionDialog.vue";
import AccountService from "../../services/account.service";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "GestionUtilisateursView",
  components: {
    TitleBar,
    TableButtonGroup,
    EmailComponent,
    ErrorComponent,
    PaginatedTable,
    ConfirmationDialog,
    AccountStatusDialog,
    GroupsEditionDialog,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.UTILISATEURS),
      error: null,
      page: null,
    };
  },
  computed: {
    ...tableComputed,
  },
  created,
  mounted() {
    this.load();
  },
  methods: {
    ...tableMethods,
    async load() {
      this.error = null;
      try {
        this.busy = true;
        let payload = this.getSearchPayload();
        let response = await AccountService.searchAccounts(payload);
        this.page = response.data;
      } catch (error) {
        this.page = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
    openStatutDialog(email) {
      this.$refs.accountStatusDialog.show({
        email,
      });
    },
    openGroupsEditor(email) {
      this.$refs.groupsEditionDialog.show({
        email,
      });
    },
    async resetPassword(email) {
      let confirmation = await this.$refs.confirmationDialog.show({
        title: "Réinitialiser le mot de passe ?",
        messages: [
          `Etes-vous sûr de vouloir réinitilialiser le mot de passe du compte <${email}> ? Cette action sera irréversible.`,
          `Si vous confirmez, le propriétaire du compte recevra un mot de passe temporaire par mail.`,
        ],
      });
      if (confirmation) {
        AccountService.resetPassword(email);
      }
    },
  },
};
</script>
