<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>Modifier les groupes</template>
    <b-container v-if="email">
      <b-form-group label="Compte utilisateur">
        <span class="labeled">
          {{ email }}
        </span>
      </b-form-group>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <b-form-group label="Groupes gérés hors Kerhis">
        <template v-for="group in groups">
          <span v-if="!group.automatique" :key="group.codeGroupe">
            <b-form-checkbox v-model="group.checked" @change="toggle(group)">
              {{ group.libelle }} ({{ group.codeGroupe }})
            </b-form-checkbox>
          </span>
        </template>
      </b-form-group>
      <b-form-group label="Groupes gérés par Kerhis">
        <template v-for="group in groups">
          <span v-if="group.automatique" :key="group.codeGroupe">
            <b-form-checkbox :disabled="true" v-model="group.checked">
              {{ group.libelle }} ({{ group.codeGroupe }})
            </b-form-checkbox>
          </span>
        </template>
      </b-form-group>
    </b-container>
    <template #modal-footer>
      <b-button block pill variant="outline-primary" @click="cancel">
        Fermer
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ErrorComponent from "../ErrorComponent.vue";
import AccountService from "../../services/account.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "GroupsEditionDialog",
  components: { ErrorComponent },
  data: () => ({
    error: null,
    email: null,
    groups: [],
    resolvePromise: null,
    rejectPromise: null,
  }),
  methods: {
    async show(options) {
      this.error = null;
      this.email = options.email;
      await this.reset();
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async reset() {
      let responses = await Promise.all([
        AccountService.getAllGroups(),
        AccountService.getAccountGroups(this.email),
      ]);
      this.groups = responses[0].data.map((g1) => {
        g1.checked = !!responses[1].data.find(
          (g2) => g2.codeGroupe === g1.codeGroupe
        );
        return g1;
      });
    },
    async toggle(group) {
      try {
        await (group.checked
          ? AccountService.addAccountToGroup(this.email, group.codeGroupe)
          : AccountService.removeAccountFromGroup(
              this.email,
              group.codeGroupe
            ));
      } catch (e) {
        this.error = UtilsService.handleError(e);
      }
      this.reset();
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>

<style scoped></style>
