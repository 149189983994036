<template>
  <StandardIconBloc
    icon="exclamation-circle-fill"
    backColor="rgb(255,235,230)"
    frontColor="rgb(222,53,11)"
    border="1px solid rgb(222,53,11)"
  >
    <slot></slot>
  </StandardIconBloc>
</template>

<script>
import StandardIconBloc from "./StandardIconBloc.vue";
export default {
  name: "DangerBloc",
  components: { StandardIconBloc },
};
</script>
