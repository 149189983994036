<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>Statut du compte</template>
    <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
    <b-container v-if="status">
      <b-form-group label="Compte utilisateur">
        {{ status.email }}
      </b-form-group>
      <b-row v-for="(category, i) in param" :key="`category_${i}`">
        <b-col cols="12">
          <label>{{ category.title }}</label>
        </b-col>
        <b-col cols="12">
          <ul>
            <li
              v-for="(criteria, j) in category.criteria"
              :key="`criteria_${i}_${j}`"
            >
              <b-icon-check-circle-fill
                v-if="status[criteria.property]"
                variant="success"
              >
              </b-icon-check-circle-fill>
              <b-icon-x-circle-fill
                v-else
                variant="danger"
              ></b-icon-x-circle-fill>
              {{ criteria.text }}
            </li>
          </ul>
        </b-col>
      </b-row>
      <DangerBloc v-b-toggle.details>
        <p>
          Toutes les conditions ci-dessus doivent &ecirc;tre remplies pour que
          l'adh&eacute;rent puisse se connecter &agrave; son compte.
        </p>
        <p><em>Cliquer pour plus d'infos</em></p>
      </DangerBloc>
      <b-collapse id="details">
        <p>
          Vous pouvez agir sur les conditions "Compte" en utilisant le bouton
          "R&eacute;initialiser". <br />
          Une fois son compte actif, l'adh&eacute;rent est autonome pour
          r&eacute;initialiser son mot de passe via "Mot de passe
          oubli&eacute;".
        </p>
        <p>
          Les exploitations accessibles sont param&eacute;tr&eacute;es dans
          Kerhis (cf. proc&eacute;dure sur le serveur Kerhis). <br />
          Les privil&egrave;ges du compte sont d&eacute;duits de ce
          param&eacute;trage et peuvent &ecirc;tre affin&eacute;s via le bouton
          "Groupes".
        </p>
      </b-collapse>
    </b-container>
    <template #modal-footer>
      <b-button block pill variant="outline-primary" @click="cancel">
        Fermer
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ErrorComponent from "../ErrorComponent.vue";
import DangerBloc from "../blocs/DangerBloc.vue";
import AccountService from "../../services/account.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "AccountStatusDialog",
  components: { ErrorComponent, DangerBloc },
  data: () => ({
    error: null,
    status: null,
    param: [
      {
        title: "Compte",
        criteria: [
          { text: "Actif", property: "compteActif" },
          { text: "Non expiré", property: "compteNonExpire" },
          { text: "Non bloqué", property: "compteNonBloque" },
        ],
      },
      {
        title: "Mot de passe",
        criteria: [
          { text: "Valide", property: "mdpValide" },
          { text: "Non expiré", property: "mdpNonExpire" },
        ],
      },
      {
        title: "Exploitation(s)",
        criteria: [
          { text: "Au moins 1 contact actif", property: "contactActif" },
          { text: "Dont le tiers est actif", property: "tiersActif" },
        ],
      },
      {
        title: "Privilèges",
        criteria: [{ text: "Au moins 1 groupe", property: "privileges" }],
      },
    ],
    resolvePromise: null,
    rejectPromise: null,
  }),
  methods: {
    async show(options) {
      this.error = null;
      this.status = null;

      try {
        let response = await AccountService.getAccountStatus(options.email);
        this.status = response.data;
      } catch (error) {
        this.error = UtilsService.handleError(error);
      }

      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>
