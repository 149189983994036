<template>
  <span v-if="value" :class="classes">
    <b-icon-at v-if="displayIcon"></b-icon-at>
    <b-link :href="`mailto:${value}`" target="_blank" @click.stop>
      {{ linkText }}
    </b-link>
  </span>
</template>

<script>
export default {
  name: "EmailComponent",
  props: ["value", "text", "icon", "classes"],
  computed: {
    linkText() {
      return this.text || this.value;
    },
    displayIcon() {
      return this.icon === true || this.icon === "true";
    },
  },
};
</script>

<style scoped></style>
